<template>
  <div>
    <table
      class="table is-striped is-fullwidth is-borderless is-spaced-top"
    >
      <thead class="has-background-info">
        <tr>
          <th class="has-text-white is-uppercase">
            type
          </th>
          <th class="has-text-white is-uppercase">
            account
          </th>
          <th class="has-text-white is-uppercase">
            contacts
          </th>
          <th class="has-text-white is-uppercase">
            actions
          </th>
        </tr>
      </thead>

      <tbody>
        <tr>
          <td>
            <b-field>
              <b-select
                v-model="payload.influencerTypeId"
                expanded
              >
                <option
                  v-for="(influencerType, index) in influencerTypes"
                  :key="index"
                  :value="influencerType.id"
                >
                  {{ influencerType.value }}
                </option>
              </b-select>
            </b-field>
          </td>

          <td>
            <b-field>
              <b-autocomplete
                v-model="payload.account"
                expanded
                field="name"
                icon-right="search"
                :data="accountsSearchResults"
                :loading="isLoading"
                @typing="getAccountsAsyncData"
                @select="(option) => (selectedAccount = option)"
              >
                <template slot-scope="props">
                  <div class="media">
                    <div class="media-content">
                      {{ props.option.name }}
                      <br>
                      <small>
                        customer number: {{ props.option.externalId }}
                      </small>
                    </div>
                  </div>
                </template>

                <template #empty>
                  No results for {{ payload.account }}
                </template>
              </b-autocomplete>
            </b-field>
          </td>

          <td>
            <b-field>
              <b-autocomplete
                v-model="contact"
                field="fullName"
                clear-on-select
                icon-right="search"
                :data="contactsSearchResults"
                :loading="isLoading"
                @typing="getContactsAsyncData"
                @select="addContact"
              >
                <template slot-scope="props">
                  <div class="media">
                    <div class="media-left">
                      <img
                        width="32"
                        :src="props.option.photo ? props.option.photo : defaultImage"
                        :alt="props.option.fullName"
                      >
                    </div>
                    <div class="media-content">
                      {{ props.option.fullName.trim() }}
                      <br>
                      <small>
                        Phone: {{ props.option.phone }}, Email:
                        <b>{{ props.option.emailAddress }}</b>
                      </small>
                    </div>
                  </div>
                </template>

                <template #empty>
                  No results for {{ contact }}
                </template>
              </b-autocomplete>
            </b-field>

            <a
              v-if="selectedAccount"
              href="#"
              class="link"
              @click="openModal('newContactFormModal')"
            >
              <strong>Create New Contact</strong>
            </a>

            <b-tag
              v-for="(item, index) in payload.contacts"
              :key="index"
              closable
              @close="removeContact(index)"
            >
              {{ item.contact }}
            </b-tag>
          </td>

          <td>
            <b-button
              icon-left="plus"
              type="is-success"
              aria-label="Add"
              @click="addInfluencer"
            >
              Add Influencer
            </b-button>
          </td>
        </tr>

        <tr
          v-for="(influencer, index) in influencers"
          :key="index"
        >
          <td>
            <span>
              {{ influencer.influencerTypeId }}
            </span>
          </td>

          <td>
            {{ influencer.account }}
          </td>

          <td>
            <b-tag
              v-for="(influencerContact, id) in influencer.contacts"
              :key="id"
            >
              {{ influencerContact.contact }}
            </b-tag>
          </td>

          <td>
            <b-button
              aria-label="Remove Influencer"
              icon-left="close"
              type="is-danger"
              @click="
                $route.name === 'EditLead'
                  ? removeInfluencer(index, influencer.accountId)
                  : removeInfluencer(index, influencer.accountId)
              "
            />
          </td>
        </tr>
      </tbody>
    </table>

    <b-modal
      v-model="isComponentModalActive"
      has-modal-card
      trap-focus
      :destroy-on-hide="false"
      aria-role="dialog"
      aria-label="create data form modal"
      aria-modal
    >
      <template #default="props">
        <accounts-form-modal
          v-if="modalName === 'newAccountFormModal'"
          module-name="leads"
          @close="props.close"
        />
        <contacts-form-modal
          v-if="modalName === 'newContactFormModal'"
          module-name="leads"
          :influencer-account-id="payload.accountId"
          @close="props.close"
          @set-contact="setContact"
        />
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import debounce from '@/utils/debounce';

import AccountsFormModal from '@/components/Shared/AccountsFormModal.vue';
import ContactsFormModal from '@/components/Shared/ContactsFormModal.vue';

import defaultImage from '@/assets/images/avatar.svg';

export default {
  name: 'LeadsInfluencersTable',

  components: {
    AccountsFormModal,
    ContactsFormModal,
  },

  data() {
    return {
      defaultImage,
      isLoading: false,
      selectedAccount: null,
      accountsSearchResults: [],
      isInfluencerContactFormModalActive: false,
      payload: {
        influencerTypeId: '',
        account: '',
        accountId: '',
        contacts: [],
      },

      selectedContact: null,
      contactsSearchResults: [],
      contact: '',
      isComponentModalActive: false,
      modalName: '',
    };
  },

  computed: {
    ...mapGetters({
      influencers: 'Leads/getLeadInfluencers',
      influencerTypes: 'Lookups/getAccountTypes',
    }),
  },

  watch: {
    selectedAccount(value) {
      if (value.accountId) {
        this.payload.accountId = value.accountId;
      }
    },
  },

  methods: {
    openModal(modalLabel) {
      this.modalName = modalLabel;
      this.isComponentModalActive = true;
    },

    setContact(payload) {
      this.payload.contacts.push({
        contact: `${payload.firstName} ${payload.lastName}`,
        contactId: payload.id,
      });
    },

    getAccountsAsyncData: debounce(async function (token) {
      this.isLoading = true;
      try {
        const response = await this.$store.dispatch('Search/searchTypeAccounts', {
          token,
          accountTypeById: this.payload.influencerTypeId,
        });
        this.accountsSearchResults = response;
      } catch (error) {
        this.accountsSearchResults = [];
      } finally {
        this.isLoading = false;
      }
    }, 500),

    getContactsAsyncData: debounce(async function (token) {
      try {
        const response = await this.$store.dispatch('Search/searchAccountContacts', {
          token,
          accountId: this.payload.accountId,
        });
        this.contactsSearchResults = response;
      } catch (error) {
        this.contactsSearchResults = [];
      }
    }, 500),

    async addInfluencer() {
      const existingContacts = [];
      const nonExistentContacts = [];
      this.influencers.forEach((influencer) => {
        if (influencer.accountId === this.payload.accountId) {
          this.payload.contacts.forEach((contact) => {
            influencer.contacts.forEach((influencerContact) => {
              if (influencerContact.contactId === contact.contactId) {
                existingContacts.push(contact);
              } else {
                nonExistentContacts.push(contact);
              }
            });
          });
        }
      });

      // check if accounts exists
      const isAccountExistent = this.influencers.find(
        (influencer) => influencer.accountId === this.payload.accountId,
      );

      if (
        isAccountExistent
        && nonExistentContacts.length > 0
      ) {
        if (this.$route.name === 'EditLead') {
          try {
            await this.$store.dispatch('Leads/createLeadInfluencer', {
              leadId: this.$route.params.leadId,
              influencer: this.payload,
            });
          } catch (error) {
            console.error(error);
          }
        }

        this.$store.commit('Leads/UPDATE_LEAD_INFLUENCER', {
          accountId: this.payload.accountId,
          contacts: nonExistentContacts,
        });
      } else {
        const typeValue = this.influencerTypes.filter(
          (influencerType) => influencerType.id === this.payload.influencerTypeId,
        )[0].value;

        if (this.$route.name === 'AddLead') {
          this.$store.commit('Leads/ADD_LEAD_INFLUENCER', { ...this.payload, influencerTypeId: typeValue });
        }

        if (this.$route.name === 'EditLead') {
          try {
            await this.$store.dispatch('Leads/createLeadInfluencer', {
              leadId: this.$route.params.leadId,
              influencer: this.payload,
            });
            this.$store.commit('Leads/ADD_LEAD_INFLUENCER', { ...this.payload, influencerTypeId: typeValue });
          } catch (error) {
            console.error(error);
          }
        }
      }

      this.payload = {
        influencerTypeId: '',
        account: '',
        accountId: '',
        contacts: [],
      };

      this.contact = '';
    },

    removeInfluencer(index, accountId) {
      if (this.$route.name === 'AddLead') {
        this.$store.commit('Leads/REMOVE_LEAD_INFLUENCER', index);
      }

      if (this.$route.name === 'EditLead') {
        this.$store.dispatch('Leads/removeLeadInfluencer', {
          leadId: this.$route.params.leadId,
          influencerId: index,
          accountId,
        });
        // this.$store.commit('Leads/REMOVE_LEAD_EDIT_INFLUENCER', index);
      }
    },

    addContact(option) {
      const isExistingContact = this.payload.contacts.find(
        (contact) => contact.contactId === option.contactId,
      );
      if (!isExistingContact) {
        this.payload.contacts.push({ contact: option.fullName, contactId: option.contactId });
      }
      this.selectedContact = null;
      this.contact = '';
      this.contactsSearchResults = [];
    },

    removeContact(index) {
      this.payload.contacts.splice(index, 1);
    },
  },
};
</script>

<style lang="css" scoped></style>
